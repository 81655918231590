.password-container{
    position: relative;
}
.password-container input[type="password"],
.password-container input[type="text"]{
    width: 100%;
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
}
.fa-eye{
    position: absolute;
    top: 50%;
    right: 4%;
    cursor: pointer;
    color: #998877;
}


.fa-eye-login{
    position: absolute;
    top: 30%;
    right: 4%;
    cursor: pointer;
    color: #998877;
}