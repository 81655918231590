.comments {
    font-size: 13px; 
    margin-top: 5px;
}

.comment-list {
    list-style: none; 
    padding: 0;
    margin: 0;
}

.comment {
    gap: 5px; 
    padding: 5px 0; 
}

.com-content {
    flex: 1; /* Prend tout l'espace disponible */
}

.com-content h6 {
    margin: 0;
}

.com-content span {
    font-size: 13px;
}

.com-content p {
    background-color: var(--dark-blue-color);
    padding: 8px 12px;
    border-radius: 5px;
    margin: 5px 0 0 0;
}

.comment a {
    font-size: 14px;
}




