/*@@@@@@@@@@@@@@   Shop Page Start Here  @@@@@@@@@@@@@@@@@*/
.shop-page {
    .section-header {
        h4 {
            span {
                font-weight: 400;
                @extend %theme-color;
            }
        }
    }
	.sidebar-widget {
		.widget-search {
            border: none;
            box-shadow: 0 0 10px rgba(136, 136, 136, .10);
            form {
                input {
                    @extend %bg-white;
                    border: 1px solid $border-color;
                }
            }
        }
	}
	.shop-title {
	    padding: 16px 20px;
	    @extend %mb-30;
        background: $primary-color;
        box-shadow: 0 0 10px rgba(136, 136, 136, .10);
        border-radius: 3px;
		p {
			@extend %mb-0;
            color: $white-color;
		}
		.product-view-mode {
			a {
                color: $white-color;
			    cursor: pointer;
				@include transition($transition);
			    &.active {
		    	    @extend %theme-color;
			    }
			    &:last-child {
			    	margin-left: 5px;
			    }
			}
		}
	}
	.shop-product-wrap {
		&.grid {
            height: auto;
			.product-list-item {
				display: none;
			}
		}
		&.list {
            height: auto !important;
			.col-12 {
				flex: 0 0 100%;
    			max-width: 100%;
				position: static !important;
			}
			.product-item {
				display: none;
			}
		}
		.product-item, .product-list-item {
			padding: 10px;
    		@extend %align-items-center;
            background: $primary-color;
	        -webkit-animation-name: zoomIn;
		    animation-name: zoomIn;
		    -webkit-animation-duration: .5s;
		    animation-duration: .5s;
            box-shadow: 0 0 10px rgba(136, 136, 136, .10);
            @include transition($transition);
            border-radius: 3px;
		    &:hover {
		    	.product-thumb {
                    .pro-thumb {
                        &::after {
                            @include transform(scale(1));
                        }
                    }
		    		.product-action-link {
						opacity: 1;
                        z-index: 9;
                        bottom: 50%;
                        transform: translate(-50%, 50%);
		    		}
		    	}
				.product-content {
					h5 {
						a {
							@extend %theme-color;
						}
					}
				}
		    }
			.product-thumb {
                @extend %rel;
                @extend %hidden;
                .pro-thumb {
                    @extend %rel;
                    &::after {
                        top: 0;
                        left: 0;
                        @extend %w-100;
                        @extend %h-100;
                        background: rgba($primary-color, $alpha: .7);
                        @extend %abs;
                        @include transform(scale(0));
                        @include transition($transition);
                    }
                    img {
                        @extend %w-100;
                        transform-origin: left;
                    }
                }
				.product-action-link {
					@extend %justify-center;
					opacity: 0;
                    bottom: -100%;
                    left: 50%;
                    @extend %w-100;
					@extend %abs;
					@extend %flex;
                    @include transform(translateX(-50%));
					@include transition($transition);
					a {
						width: 40px;
					    height: 40px;
					    line-height: 40px;
					    text-align: center;
					    border-radius: 100%;
						color: $white-color;
                        margin: 5px;
                        @extend %bg-theme;
						@include transition($transition);
						&.view-modal {
    						cursor: pointer;
						}
					}
				}
			}
			.product-content {
				padding-top: 20px;
    			text-align: center;
				h5 {
				    @extend %m-0;
				    margin-bottom: 5px;
					a {
					    @extend %white-color;
    					text-decoration: none;
						@include transition($transition);
					}
				}
				p {
				    margin-bottom: 5px;
					i {
						color: $white-color;
					}
				}
			}
		}
		.product-list-item {
			@extend %flex;
			.product-thumb {
				@extend %w-100;
				@include breakpoint(md) {
					width: 35%;
                }
                img {
                    @extend %w-100;
                }
			}
			.product-content {
				@extend %w-100;
				text-align: left;
				padding-left: 20px;
				@include breakpoint(md) {
					width: 65%;
				}
			}
		}
    }
	.modal {
		&.show {
			display: block;
		    opacity: 1;
		    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
		    filter: alpha(opacity=100);
		    visibility: visible;
		    pointer-events: visible;
		    z-index: 99999999;
		    -webkit-animation: slideInDown 0.4s forwards;
		    animation: slideInDown 0.4s forwards;
		    overflow-y: scroll;
	        background: #000;
		}
		.modal-dialog {
			.modal-content {
				button {
					&.close {
					    width: 40px;
					    height: 40px;
					    top: 0;
					    right: 0px;
					    background: $theme-color;
					    z-index: 9;
                        color: $white-color;
					    text-shadow: none;
                        opacity: 1;
                        outline: none;
					    @extend %abs;
                        border: none;
					}
				}
				.modal-body {
					background: $primary-color;
					.product-details-inner {
                        .thumb {
                            @extend %rel;
                            @extend %hidden;
                            .pro-thumb {
                                img {
                                    @extend %w-100;
                                }
                            }
                        }
                        .product-container {
                            @extend %hidden;
                        }
						.product-content {
							margin-top: 30px;
							@include breakpoint(sm) {
								margin-top: 0;
							}
                            p {
                                i {
                                    color: $star;
                                }
                            }
						}
						.cart-button {
							margin-top: 30px;
						    @extend %align-items-center;
						    @extend %flex;
							.cart-plus-minus {
								@extend %m-0;
							}
							.default-button {
                                margin-left: 20px;
							}
						}
					}
				}
			}
		}
    }
    .paginations {
        @extend %mb-0;
    }
}



// widget css start here
.widget {

    .widget-header {
        h5 {
            color: $white-color;
            font-weight: 700;
            @extend %rel;
            margin-bottom: 40px;
            &::before {
                @extend %abs;
                width: 40px;
                height: 4px;
                background-color: $theme-color;
                bottom: -20px;
                border-radius: 2px;
            }

            &::after {
                position: absolute;
                content: "";
                border-bottom: 5px dotted $theme-color;
                width: 30px;
                bottom: -20px;
                left: 43px;
            }
        }
    }

    &.widget-search {
        padding: 30px;

        h5 {
            margin-bottom: 40px;
        }

        .search-wrapper {
            @extend %rel;

            input {
                height: 50px;
                padding: 13px 23px;
                line-height: 50px;
                border: none;
                width: 100%;
                outline: none;
                color: $white-color;
                background-color: rgba($color: $white-color, $alpha: .10);
                @extend %mb-0;
            }

            button {
                @extend %abs;
                padding: 0;
                top: 0;
                right: 0;
                line-height: 50px;
                width: 50px;
                height: 50px;
                outline: none;
                border: none;
                cursor: pointer;
                color: $white-color;
                background-color: inherit;
            }
        }
    }

    &.recipe-categori {
        @extend %hidden;
        .widget-wrapper {
            padding: 25px 15px;
            .recipe-item {
                border: 1px dotted $border-color;
                border-width: 1px;
                border-top: none;
                border-right: none;
                margin: 5px;
            }
        }
    }

    &.shop-widget {
        @extend %p-30;
        .widget-wrapper {
            .shop-menu {
                @extend %m-0;
                li {
                    list-style: none;
                    border-bottom: 1px solid rgba($white-color, $alpha: .10);
                    padding: 12px 0px;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.menu-item-has-children {
                        @extend %mr-0;
                    }
                    &.open> {
                        a {
                            @extend %white-color;
                        }
                    }
                    a {
                        @extend %d-block;
                        @extend %white-color;
                        @extend %w-100;
                        font-weight: 500;
                    }
                    .shop-submenu {
                        margin: 0;
                        @extend %m-0;
                        @extend %pt-15;
                        @extend %pb-15;
                        @extend %w-100;
                        display: none;
                        li {
                            padding: 2px 0;
                            border-bottom: none;
                            &.open> {
                                a {
                                    @extend %white-color;
                                }
                            }
                            a {
                                @extend %white-color;
                                @extend %text-capitalize;
                                font-size: 14px;
                                font-weight: 400;
                                @include transition($transition);
                                &.active, &:hover {
                                    @extend %white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.recent-product {
        @extend %p-30;
        ul {
            li {
                padding-block: 5px;
                &:hover {
                    .post-thumb {
                        img {
                            transform: scale(1.2)
                        }
                    }
                    .post-content {
                        h6 {
                            color: $theme-color;
                        }
                    }
                }
                .post-thumb {
                    width: 80px;
                    height: 80px;
                    border-radius: 3px;
                    @extend %of-hidden;
                    img {
                        object-fit: cover;
                    }
                }
                .post-content {
                    h6 {
                        @extend %mb-0;
                    }
                    p {
                        @extend %mb-5;
                    }
                }
            }
        }
    }
}


// menu-item-has-children
.catmenu-item-has-children {
    >a {
        @extend %rel;

        &::after {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 25px;
            width: 10px;
            height: 2px;
            background: $white-color;
        }

        &::before {
            @extend %abs;
            top: 50%;
            transform: translateY(-50%);
            right: 29px;
            width: 2px;
            height: 10px;
            background-color: $white-color;
            @include transition($transition);
        }

        @include breakpoint(lg) {
            &::after {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 10px;
                height: 2px;
                background-color: $white-color;
            }

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 4px;
                width: 2px;
                height: 10px;
                background-color: $white-color;
                @include transition($transition);
            }
        }
    }
    .menu-item-has-children {
        a {
            @include breakpoint(lg) {
                &::after {
                    right: 11px;
                }
    
                &::before {
                    right: 15px;
                }
            }
            &:hover {
                @include breakpoint(lg) {
                    &::after, &::before {
                        background: $white-color;
                    }
                }
            }
        }
    }

    &.open {
        >a {

            &:before {
                transform: translateY(-50%) rotate(90deg);
                opacity: 0;
            }
        }
    }
}
